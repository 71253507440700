<template>
  <el-popover
    placement="bottom"
    :content="billable ? 'Billable' : 'Not billable'"
    trigger="hover"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <i
      slot="reference"
      class="fas fa-dollar-sign billable-icon"
      :class="billable ? 'selected' : ''"
      @click="saveChanges"
    />
  </el-popover>
</template>

<script>
import { TimeEntry } from '@timeragent/core';

export default {
  name: 'BillableIcon',
  props: {
    timeEntry: {
      type: TimeEntry || [TimeEntry],
      required: true,
    },
    mainEntry: {
      type: TimeEntry,
      required: false,
      default: () => TimeEntry,
    },
  },
  computed: {
    billable() {
      return this.timeEntry.length ? this.mainEntry.isBillable : this.timeEntry.isBillable;
    },
  },
  methods: {
    saveChanges() {
      this.mainEntry.isBillable = !this.mainEntry.isBillable;

      if (this.timeEntry.length) {
        this.timeEntry.forEach(entry => {
          entry.isBillable = this.mainEntry.isBillable;
          entry.save();

          return entry;
        });
      }

      if (this.timeEntry) {
        this.timeEntry.isBillable = this.mainEntry.isBillable;

        if (this.timeEntry.active || this.timeEntry.endTime) {
          this.timeEntry.save();
        }
      }
    },
  },
};
</script>

<style>
.el-popover {
  min-width: unset;
}
</style>

<style
    lang="stylus"
    rel="stylesheet/css"
  scoped>

::v-deep {
  .el-popover--plain {
    padding: 0;
  }
}
.billable-icon {
  font-size: 16px;
  color: #bfc1c5;
}

.billable-icon:hover {
  color: #525252;
  cursor: pointer;
}

.selected {
  color: #525252;
}

</style>
